import { render, staticRenderFns } from "./videosinternas.vue?vue&type=template&id=af42b25a&scoped=true"
import script from "./videosinternas.vue?vue&type=script&lang=js"
export * from "./videosinternas.vue?vue&type=script&lang=js"
import style0 from "./videosinternas.vue?vue&type=style&index=0&id=af42b25a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af42b25a",
  null
  
)

export default component.exports